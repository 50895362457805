import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function LastMileSolutionPicksUpSpeed () {
  const shareTitle = 'Trexity picks up speed with merchants'
  const articleLink = 'https://www.obj.ca/techopia-article/shipping-platform-trexitys-last-mile-solution-picks-speed-merchants'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Shipping platform Trexity’s ’last-mile’ solution picks up speed with merchants</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>May 25, 2020</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/alok-ahuja.jpg" alt="Alok Ahuja" />
                <div className="snippet">The following preview is from the <a href={articleLink} target="_blank" rel="noreferrer">Ottawa Business Journal</a></div>
                <p>During his years as a manager at Ottawa tech giant Shopify, Alok Ahuja had a front-row seat to witness e-commerce gradually strengthen its grip on the global retail sector.</p>
                <p>And from where Ahuja sat, what he saw suggested the shop-from-home experience still had plenty of room for improvement. While consumers suddenly had access to millions of products from companies all over the world at their fingertips, they were relying on a handful of shipping outfits to deliver those items in a timely fashion.</p>
                <p>In many cases, he argues, their definition of timely simply wasn’t quick enough.</p>
                <a href={articleLink} target="_blank" rel="noreferrer">Read full article on Ottawa Business Journal</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/zapiet-and-trexity-partner-up" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Zapiet and Trexity partner up to offer Merchants same-day local delivery</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
